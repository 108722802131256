import React, { useEffect, useState, useMemo, useContext } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
} from "reactstrap"
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import Spinners from "../../../components/Common/Spinner"
import TableContainer from "../../../components/Common/TableContainer"

import ModalContext from "../../../contexts/ModalContext"

import { checkScope, capitalize } from "helpers/utility_helper"

import moment from "moment"

import { getContent, deleteContent } from "../../../services/api/module/Article"

const RowStatus = ({ status }) => {
  switch (status) {
    case "active":
      return <Badge className="bg-success"> Active </Badge>

    case "inactive":
      return <Badge className="bg-warning"> Inactive </Badge>

    case "deleted":
      return <Badge className="bg-danger"> Deleted </Badge>

    default:
      return <>{`-`}</>
  }
}

const ListPage = () => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Api */
  const API = {
    getContentList: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await getContent({ params: payload })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    delContentList: async payload => {
      try {
        const response = await deleteContent({ data: payload })
        const resData = response.data

        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Delete Data Success",
            content: "",
            onClose: () => {
              fetchAPI()
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }
    },
  }
  /* End Api */

  /* First event render */
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])

  const fetchAPI = () => {
    setContentListLoading(true)

    API.getContentList({}).then(_rs => {
      // console.log("_rs >>", _rs)

      if (_rs.status === true) {
        setContentList(_rs.result)
        setContentListLoading(false)
      }
    })
  }

  const dataTemp = [
    {
      categoryId: 1,
      categoryName: "รถมอเตอร์ไซค์ต่ำกว่า 400 cc.",
      createdDate: "2024-09-13 09:00:00",
      updatedDate: null,
      categoryStatus: "active",
      sequence: 1,
    },
    {
      categoryId: 2,
      categoryName: "รถบิ๊กไบค์",
      createdDate: "2024-09-13 09:00:00",
      updatedDate: null,
      categoryStatus: "active",
      sequence: 2,
    },
    {
      categoryId: 3,
      categoryName: "ผลิตภัณฑ์ทางน้ำ",
      createdDate: "2024-09-13 09:00:00",
      updatedDate: null,
      categoryStatus: "active",
      sequence: 3,
    },
    {
      categoryId: 4,
      categoryName: "รถกอล์ฟ",
      createdDate: "2024-09-13 09:00:00",
      updatedDate: null,
      categoryStatus: "active",
      sequence: 4,
    },
    {
      categoryId: 5,
      categoryName: "ออนไลน์ช้อป",
      createdDate: "2024-09-13 09:00:00",
      updatedDate: null,
      categoryStatus: "inactive",
      sequence: 5,
    },
  ]

  useEffect(() => {
    // fetchAPI()

    if (dataTemp.length > 0) {
      setContentListLoading(true)

      for (let i = 0; i < dataTemp.length; i++) {
        setContentList(prevItem => [
          ...prevItem,
          {
            categoryId: dataTemp[i].categoryId,
            categoryName: dataTemp[i].categoryName,
            createdDate: dataTemp[i].createdDate,
            updatedDate: dataTemp[i].updatedDate,
            categoryStatus: dataTemp[i].categoryStatus,
            sequence: dataTemp[i].sequence,
          },
        ])
      }

      setContentListLoading(false)
    }
  }, [])
  /* End First event render */

  useEffect(() => {
    // console.log("contentList >>", contentList);
  }, [contentList, contentListLoading])

  /* Set table column */
  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }}
                  readOnly
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      {
        header: "Category",
        accessorKey: "categoryName",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => cellProps.row.original.categoryName,
      },
      {
        header: "Sort Order",
        accessorKey: "sequence",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => cellProps.row.original.sequence,
      },
      {
        header: "Created At",
        accessorKey: "createdDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps =>
          moment(cellProps.row.original.createdDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
      },
      {
        header: "Updated At",
        accessorKey: "updatedDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps =>
          cellProps.row.original.updatedDate !== null
            ? moment(cellProps.row.original.updatedDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "-",
      },
      {
        header: "Status",
        accessorKey: "categoryStatus",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => (
          <RowStatus status={cellProps.row.original.categoryStatus} />
        ),
      },
      {
        header: "Tools",
        accessorKey: "categoryId",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          let { categoryId } = cellProps?.row?.original ?? {}
          return (
            <>
              {/* {checkScope("systemsettings:usersetting:view") && (
                <Link
                  className={`btn btn-info`}
                  to={`/product/category/view/${categoryId}`}
                >
                  <i className={`bx bx-show-alt`}></i>
                </Link>
              )}
              &nbsp; */}
              {checkScope("systemsettings:usersetting:update") && (
                <Link
                  className={`btn btn-warning`}
                  to={`/product/category/edit/${categoryId}`}
                >
                  <i className={`bx bx-edit-alt`}></i>
                </Link>
              )}
              &nbsp;
              {checkScope("systemsettings:usersetting:delete") && (
                <a
                  className={`btn btn-danger`}
                  onClick={() => onDeleteRow([categoryId])}
                >
                  <i className={`bx bx-trash`}></i>
                </a>
              )}
            </>
          )
        },
      },
    ],
    [selectedContentId, contentList]
  )
  /* End Set table column */

  /* Handle delete row */
  const onDeleteRow = (ids = [], name = "") => {
    // console.log('onDeleteRow'+ JSON.stringify(ids))

    mAlert.info({
      type: "confrim",
      title: "Alert Confrim",
      subTitle: `Are you sure Delete ${name} ?`,
      mode: "red",
      content: "",
      onClose: () => {},
      onYes: () => {
        const payload = { ids: ids }
        API.delContentList(payload)
      },
      onNo: () => {
        // console.log("Cancel")
      },
    })
  }
  /* End handle delete row */

  /* Handle selection row */
  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }
  /* End Handle selection row */

  //meta title
  document.title = `Product Category | ${process.env.REACT_APP_CMS_TITLE}`

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title=`Product Category` breadcrumbItem="List" /> */}
          <Breadcrumbs
            title={`Product Category List`}
            breadcrumbItems={[
              { title: "Product Category", link: "product/category" },
              { title: "List" },
            ]}
          />

          {contentListLoading ? (
            <Spinners setLoading={setContentListLoading} />
          ) : (
            <TableContainer
              isAddButton={true}
              addRowLink={`/create`}
              columns={columns}
              data={contentList || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search"
              pagination="pagination"
              paginationWrapper="dataTables_paginate paging_simple_numbers"
              tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              rootMenu="systemsettings"
              subMenu="usersetting"
              isCustomPageSize={true}
              isSelected={selectedContentId.length > 0}
              onClearSelected={() => setSelectedContentId([])}
              onDeleleSelected={() =>
                onDeleteRow(_.cloneDeep(selectedContentId))
              }
              isExportButton={true}
              exportOption={{
                filename: "product_category",
                dateColumns: [],
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

ListPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ListPage)
