import React, { useEffect, useState, useMemo, useContext, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  CardHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import Select from "react-select"

import ModalContext from "../../contexts/ModalContext"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import fn_helper from "helpers/fn_helper"

import Sortable from "sortablejs"

import {
  createSurvey,
  getSurvey,
  updateSurvey,
} from "../../services/api/module/Survey"

const STATUS_OPTION = [
  { id: "inactive", name: "Inactive" },
  { id: "active", name: "Active" },
]

const SurveyFormTemplatePage = ({ pageView = "create" }) => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Navigate */
  const navigate = useNavigate()
  /* End Navigate */

  /* Api */
  const API = {
    createContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await createSurvey({ data: payload })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/survey")
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    getContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await getSurvey({
          params: payload,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    updateContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await updateSurvey({
          data: payload,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/survey")
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
  }
  /* End Api */

  /* Form Validation */
  const [dataContent, setDataContent] = useState(null)
  const [dataAnswer, setDataAnswer] = useState([])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      questionId:
        dataContent !== null && dataContent.id ? dataContent.id : "",
      questionTh:
        dataContent !== null && dataContent.questionTh
          ? dataContent.questionTh
          : "",
      questionEn:
        dataContent !== null && dataContent.questionEn
          ? dataContent.questionEn
          : "",
      order:
        dataContent !== null && dataContent.order
          ? dataContent.order
          : "",
      status: dataContent !== null ? dataContent.status : "active",
      answer:
        dataAnswer.length > 0
          ? dataAnswer
          : [{ id: 1, answerId: "", th: "", en: "", status: "active" }],
    },
    validationSchema: Yup.object({
      questionTh: Yup.string().required("Please Enter Question Thai"),
      questionEn: Yup.string().required("Please Enter Question Eng"),
      order: Yup.string().required("Please Enter Order"),
      status: Yup.string().required("Please Select Status"),
      answer: Yup.array().of(
        Yup.object({
          th: Yup.string().required("Please Enter Answer Thai"),
          en: Yup.string().required("Please Enter Answer Eng"),
          status: Yup.string().required("Please Select Status"),
        })
      ),
    }),
    onSubmit: values => {
      console.log("🚀 ~ Survey ~ values:", values)
      let payload = {
        questionTh: values.questionTh,
        questionEn: values.questionEn,
        order: values.order,
        status: values.status,
        answer: values.answer,
      }
      if (typeof values.questionId !== "undefined") {
        payload = {
          ...payload,
          id: values.questionId,
        }
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.updateContent(payload)
      } else {
        API.createContent(payload)
      }
    },
  })
  /* End Form Validation */

  /* First event render */
  const qParams = useParams()
  const qContentId = qParams.id

  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      API.getContent({ id: qContentId }).then(_rs => {
        // console.log(_rs)

        if (_rs.status === true) {
          if (_rs.result.length > 0) {
            setDataContent(_rs.result[0])
          } else {
            navigate("/survey")
          }
        }
      })
    }
  }, [])

  if (["update", "view"].includes(pageView)) {
    useEffect(() => {
      // console.log("dataContent >>", dataContent)

      if (dataContent !== null && Object.keys(dataContent).length > 0) {
        if (
          typeof dataContent.answer !== "undefined" &&
          dataContent.answer.length > 0
        ) {
          for (let i = 0; i < dataContent.answer.length; i++) {
            setDataAnswer(prevItem => [
              ...prevItem,
              {
                id: i,
                answerId: dataContent.answer[i].id,
                th: dataContent.answer[i].answerTh,
                en: dataContent.answer[i].answerEn,
                status: dataContent.answer[i].status,
              },
            ])
          }
        }
      }
    }, [dataContent])
  }
  /* End First event render */

  /* Survey Answer */
  const [openAnswerAccordion, setOpenAnswerAccordion] = useState("2")

  const toggleAnswerAccordion = id => {
    if (openAnswerAccordion.includes(id)) {
      setOpenAnswerAccordion(openAnswerAccordion.filter(i => i !== id))
    } else {
      setOpenAnswerAccordion([...openAnswerAccordion, id])
    }
  }

  const addItemAnswer = () => {
    const answer = validation.values.answer
    const newId = answer.length ? answer[answer.length - 1].id + 1 : 1
    validation.setFieldValue("answer", [
      ...answer,
      { id: newId, answerId: "", th: "", en: "", status: "active" },
    ])
  }

  const delItemAnswer = index => {
    const answer = validation.values.answer
    if (answer.length > 1) {
      validation.setFieldValue(
        "answer",
        answer.filter((_, i) => i !== index)
      )
    }
  }

  useEffect(() => {
    const sortable = Sortable.create(document.getElementById("surveyAnswer"), {
      animation: 150,
      onEnd: evt => {
        const newInputs = Array.from(validation.values.answer)
        const [removed] = newInputs.splice(evt.oldIndex, 1)
        newInputs.splice(evt.newIndex, 0, removed)
        validation.setFieldValue("answer", newInputs)
      },
    })

    return () => {
      sortable.destroy()
    }
  }, [validation.values.answer])
  /* End Survey Answer */

  //meta title
  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Survey | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={`${PAGE_VIEW} Survey`}
            breadcrumbItems={[
              { title: "Survey", link: "survey" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <div className="card-title">Survey Infomation</div>
                      <p className="card-title-desc mb4">
                        Fill all information below
                      </p>
                    </Row>
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="questionTh"
                          className="col-md-12 col-form-label"
                        >
                          Question Name Thai
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="questionTh"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="questionTh"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.questionTh}
                            invalid={
                              validation.touched.questionTh &&
                              validation.errors.questionTh
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.questionTh &&
                            validation.errors.questionTh && (
                              <FormFeedback type="invalid">
                                {validation.errors.questionTh}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="questionEn"
                          className="col-md-12 col-form-label"
                        >
                          Question Name Eng
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="questionEn"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="questionEn"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.questionEn}
                            invalid={
                              validation.touched.questionEn &&
                              validation.errors.questionEn
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.questionEn &&
                            validation.errors.questionEn && (
                              <FormFeedback type="invalid">
                                {validation.errors.questionEn}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-3 mb-3">
                        <label
                          htmlFor="order"
                          className="col-md-12 col-form-label"
                        >
                          Sort Order
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="order"
                            className="form-control"
                            type="number"
                            name="order"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.order}
                            invalid={
                              validation.touched.order &&
                              validation.errors.order
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                            min="1"
                          />
                          {validation.touched.order &&
                            validation.errors.order && (
                              <FormFeedback type="invalid">
                                {validation.errors.order}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-3 mb-3">
                        <label
                          htmlFor="status"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="status"
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(
                              STATUS_OPTION,
                              `${validation.values.status}`
                            )}
                            onChange={value =>
                              validation.setFieldValue("status", value.id)
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${
                              validation.touched.status &&
                              validation.errors.status
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.status &&
                            validation.errors.status && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.status}
                              </div>
                            )}
                          {validation.touched.status &&
                            validation.errors.status && (
                              <FormFeedback type="invalid">
                                {validation.errors.status}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="card-title">
                          Survey Answer List{" "}
                          <small
                            className="text-secondary"
                            style={{ fontWeight: "lighter" }}
                          >
                            {`(Drag and drop for sorting)`}
                          </small>
                        </div>
                        <a className="btn btn-success" onClick={addItemAnswer}>
                          Add Answer
                        </a>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Accordion
                          open={openAnswerAccordion}
                          toggle={toggleAnswerAccordion}
                          stayopen="true"
                          id="surveyAnswer"
                          className="shadow-sm"
                        >
                          {validation.values.answer.map((item, index) => (
                            <AccordionItem key={item.id} data-id={item.id}>
                              <AccordionHeader targetId={`answer${index}`}>
                                {`${index + 1}. Answer`}
                              </AccordionHeader>
                              <AccordionBody accordionId={`answer${index}`}>
                                <Row>
                                  <Col className="col-12 mb-3">
                                    <label
                                      htmlFor={`answer[${index}].th`}
                                      className="col-md-12 col-form-label"
                                    >
                                      Answer Thai
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        type="text"
                                        name={`answer[${index}].th`}
                                        value={item.th}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        placeholder={``}
                                        invalid={
                                          !!(
                                            validation.touched.answer &&
                                            validation.touched.answer[index] &&
                                            validation.errors.answer &&
                                            validation.errors.answer[index]?.th
                                          )
                                        }
                                        disabled={pageView === "view"}
                                      />
                                      {validation.touched.answer &&
                                        validation.touched.answer[index] &&
                                        validation.errors.answer &&
                                        validation.errors.answer[index]?.th && (
                                          <FormFeedback type="invalid">
                                            {validation.errors.answer[index].th}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <label
                                      htmlFor={`answer[${index}].en`}
                                      className="col-md-12 col-form-label"
                                    >
                                      Answer Eng
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        type="text"
                                        name={`answer[${index}].en`}
                                        value={item.en}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        placeholder={``}
                                        invalid={
                                          !!(
                                            validation.touched.answer &&
                                            validation.touched.answer[index] &&
                                            validation.errors.answer &&
                                            validation.errors.answer[index]?.en
                                          )
                                        }
                                        disabled={pageView === "view"}
                                      />
                                      {validation.touched.answer &&
                                        validation.touched.answer[index] &&
                                        validation.errors.answer &&
                                        validation.errors.answer[index]?.en && (
                                          <FormFeedback type="invalid">
                                            {validation.errors.answer[index].en}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="col-3">
                                    <label
                                      htmlFor={`answer[${index}].status`}
                                      className="col-md-12 col-form-label"
                                    >
                                      Status (Active/Inactive)
                                    </label>
                                    <div className="col-md-12">
                                      <Select
                                        name={`answer[${index}].status`}
                                        placeholder="Select Status"
                                        value={fn_helper.FNFORM.getObjectValue(
                                          STATUS_OPTION,
                                          `${item.status}`
                                        )}
                                        onChange={selectedOption => {
                                          validation.setFieldValue(
                                            `answer[${index}].status`,
                                            selectedOption.id
                                          )
                                        }}
                                        onBlur={() =>
                                          validation.setFieldTouched(
                                            `answer[${index}].status`,
                                            true
                                          )
                                        }
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.name}
                                        options={STATUS_OPTION}
                                        className={`select2-selection ${
                                          validation.touched.answer &&
                                          validation.touched.answer[index] &&
                                          validation.errors.answer &&
                                          validation.errors.answer[index]
                                            ?.status
                                            ? "input-err"
                                            : ""
                                        }`}
                                        isDisabled={pageView === "view"}
                                      />
                                      {validation.touched.answer &&
                                        validation.touched.answer[index] &&
                                        validation.errors.answer &&
                                        validation.errors.answer[index]
                                          ?.status && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.answer[index]
                                                .status
                                            }
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="mb-3">
                                    <label
                                      className="col-md-12 col-form-label"
                                      style={{ visibility: "hidden" }}
                                    >
                                      {`_`}
                                    </label>
                                    <Button
                                      color="danger"
                                      onClick={() => delItemAnswer(index)}
                                      disabled={
                                        pageView === "view" ||
                                        validation.values.answer.length === 1
                                      }
                                    >
                                      <i className="bx bx-trash"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div
                  className="mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "8px",
                  }}
                >
                  {pageView == "view" ? (
                    <>
                      <button
                        className="btn btn-primary w-md"
                        onClick={() => navigate("/survey")}
                      >
                        BACK
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-primary w-md">
                        SAVE
                      </button>
                      <button
                        className="btn btn-secondary w-md"
                        onClick={() => navigate("/survey")}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

SurveyFormTemplatePage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SurveyFormTemplatePage)
