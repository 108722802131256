import React, { useEffect, useState, useMemo, useContext, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  CardHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import Select from "react-select"

import ModalContext from "../../../contexts/ModalContext"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import fn_helper from "helpers/fn_helper"

import Sortable from "sortablejs"

import {
  createContent,
  getContent,
  updateContent,
} from "../../../services/api/module/Article"

const STATUS_OPTION = [
  { id: "inactive", name: "Inactive" },
  { id: "active", name: "Active" },
]

const FormTemplatePage = ({ pageView = "create" }) => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Navigate */
  const navigate = useNavigate()
  /* End Navigate */

  /* Api */
  const API = {
    createContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await createContent({ data: payload })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate(`/product/category`)
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    getContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await getContent({
          params: payload,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    updateContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await updateContent({
          data: payload,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate(`/product/category`)
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
  }
  /* End Api */

  /* Form Validation */
  const [dataContent, setDataContent] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryId:
        dataContent !== null && dataContent.categoryId
          ? dataContent.categoryId
          : "",
      categoryName:
        dataContent !== null && dataContent.categoryName
          ? dataContent.categoryName
          : pageView === "update"
          ? "รถมอเตอร์ไซค์ต่ำกว่า 400 cc."
          : "",
      categoryStatus:
        dataContent !== null ? dataContent.categoryStatus : "active",
      sequence:
        dataContent !== null && dataContent.sequence !== null
          ? dataContent.sequence
          : pageView === "update"
          ? 1
          : 0,
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required("Please Enter Category Name"),
      categoryStatus: Yup.string().required("Please Select Status"),
    }),
    onSubmit: values => {
      console.log("🚀 ~ Survey ~ values:", values)
      let payload = {
        categoryName: values.categoryName,
        categoryStatus: values.categoryStatus,
        sequence: values.sequence,
      }
      if (typeof values.categoryId !== "undefined") {
        payload = {
          ...payload,
          categoryId: values.categoryId,
        }
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      // if (pageView === "update") {
      //   API.updateContent(payload)
      // } else {
      //   API.createContent(payload)
      // }
    },
  })
  /* End Form Validation */

  /* First event render */
  const qParams = useParams()
  const qContentId = qParams.id

  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      // API.getContent({ id: qContentId }).then(_rs => {
      //   // console.log(_rs)
      //   if (_rs.status === true) {
      //     if (_rs.result.length > 0) {
      //       setDataContent(_rs.result[0])
      //     } else {
      //       navigate(`/product/category`)
      //     }
      //   }
      // })
    }
  }, [])
  /* End First event render */

  //meta title
  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `Product Category | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={`${PAGE_VIEW} Product Category`}
            breadcrumbItems={[
              { title: "Product Category", link: "product/category" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <div className="card-title">Basic Infomation</div>
                      <p className="card-title-desc mb4">
                        Fill all information below
                      </p>
                    </Row>
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="categoryName"
                          className="col-md-12 col-form-label"
                        >
                          Category Name
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="categoryName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="categoryName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.categoryName}
                            invalid={
                              validation.touched.categoryName &&
                              validation.errors.categoryName
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.categoryName &&
                            validation.errors.categoryName && (
                              <FormFeedback type="invalid">
                                {validation.errors.categoryName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-3 mb-3">
                        <label
                          htmlFor="sequence"
                          className="col-md-12 col-form-label"
                        >
                          Sort Order
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="sequence"
                            className="form-control"
                            type="number"
                            name="sequence"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sequence}
                            invalid={
                              validation.touched.sequence &&
                              validation.errors.sequence
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                            min="0"
                          />
                          {validation.touched.sequence &&
                            validation.errors.sequence && (
                              <FormFeedback type="invalid">
                                {validation.errors.sequence}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-3 mb-3">
                        <label
                          htmlFor="categoryStatus"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="categoryStatus"
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(
                              STATUS_OPTION,
                              `${validation.values.categoryStatus}`
                            )}
                            onChange={value =>
                              validation.setFieldValue(
                                "categoryStatus",
                                value.id
                              )
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${
                              validation.touched.categoryStatus &&
                              validation.errors.categoryStatus
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.categoryStatus &&
                            validation.errors.categoryStatus && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.categoryStatus}
                              </div>
                            )}
                          {validation.touched.categoryStatus &&
                            validation.errors.categoryStatus && (
                              <FormFeedback type="invalid">
                                {validation.errors.categoryStatus}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div
                  className="mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "8px",
                  }}
                >
                  {pageView == "view" ? (
                    <>
                      <button
                        className="btn btn-primary w-md"
                        onClick={() => navigate(`/product/category`)}
                      >
                        BACK
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-primary w-md">
                        SAVE
                      </button>
                      <button
                        className="btn btn-secondary w-md"
                        onClick={() => navigate(`/product/category`)}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

FormTemplatePage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FormTemplatePage)
