import { get, post, put } from "../Config"

export function SearchBannerPlatform({ params = {} }) {
  return get({ url: `/v1/admin/bannerPlatform`, params })
}
export function GetBannerPlatformById({ id }) {
  return get({ url: `/v1/admin/bannerPlatform/${id}` })
}
export function CreateBannerPlatform({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerPlatform`, params, data })
}
export function UpdateBannerPlatform({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/bannerPlatform/${id}`, params, data })
}
export function DeleteBannerPlatform({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerPlatform/mutiDelete`, params, data })
}
