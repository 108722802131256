import { get, post, put } from "../Config"

export function DropdownBannerPlatform({ params = {} }) {
  return new Promise((resolve, reject) => {
    resolve(get({ url: `/v1/admin/dropdown/bannerPlatform`, params }))
  })
}
export function DropdownBannerPage({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/bannerPage`, params })
}
