import React, { useEffect, useState, useMemo, useContext, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  CardHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import Select from "react-select"
import { Editor } from "@tinymce/tinymce-react"

import ModalContext from "../../contexts/ModalContext"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import fn_helper from "helpers/fn_helper"

import Sortable from "sortablejs"

import {
  createContent,
  getContent,
  updateContent,
} from "../../services/api/module/Article"

const STATUS_OPTION = [
  { id: "inactive", name: "Inactive" },
  { id: "active", name: "Active" },
]

const FormTemplatePage = ({ pageView = "create" }) => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Navigate */
  const navigate = useNavigate()
  /* End Navigate */

  /* Api */
  const API = {
    createContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await createContent({ data: payload })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate(`/article`)
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    getContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await getContent({
          params: payload,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    updateContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await updateContent({
          data: payload,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate(`/article`)
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
  }
  /* End Api */

  /* Form Validation */
  const [dataContent, setDataContent] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mainId:
        dataContent !== null && dataContent.mainId ? dataContent.mainId : "",
      contentSubject:
        dataContent !== null && dataContent.sbArticleContent[0].contentSubject
          ? dataContent.sbArticleContent[0].contentSubject
          : "",
      contentDetail:
        dataContent !== null && dataContent.sbArticleContent[0].contentDetail
          ? dataContent.sbArticleContent[0].contentDetail
          : "",
      contentStatus:
        dataContent !== null && dataContent.sbArticleContent[0].contentStatus
          ? dataContent.sbArticleContent[0].contentStatus
          : "active",
    },
    validationSchema: Yup.object({
      contentSubject: Yup.string().required("Please Enter Title"),
      contentStatus: Yup.string().required("Please Select Status"),
    }),
    onSubmit: values => {
      console.log("🚀 ~ Survey ~ values:", values)
      let payload = {
        contentSubject: values.contentSubject,
        contentDetail: values.contentDetail,
        contentStatus: values.contentStatus,
      }
      if (typeof values.mainId !== "undefined") {
        payload = {
          ...payload,
          mainId: values.mainId,
        }
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      // if (pageView === "update") {
      //   API.updateContent(payload)
      // } else {
      //   API.createContent(payload)
      // }
    },
  })
  /* End Form Validation */

  /* First event render */
  const qParams = useParams()
  const qContentId = qParams.id

  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      API.getContent({ mainId: qContentId }).then(_rs => {
        // console.log(_rs)

        if (_rs.status === true) {
          if (_rs.result.length > 0) {
            setDataContent(_rs.result[0])
          } else {
            navigate(`/article`)
          }
        }
      })
    }
  }, [])
  /* End First event render */

  useEffect(() => {
    console.log("dataContent >>", dataContent)
  }, [dataContent])

  //meta title
  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Article | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={`${PAGE_VIEW} Article`}
            breadcrumbItems={[
              { title: "Article", link: "article" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <div className="card-title">Basic Infomation</div>
                      <p className="card-title-desc mb4">
                        Fill all information below
                      </p>
                    </Row>
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="contentSubject"
                          className="col-md-12 col-form-label"
                        >
                          Atricle Title
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="contentSubject"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="contentSubject"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contentSubject}
                            invalid={
                              validation.touched.contentSubject &&
                              validation.errors.contentSubject
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.contentSubject &&
                            validation.errors.contentSubject && (
                              <FormFeedback type="invalid">
                                {validation.errors.contentSubject}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="slug"
                          className="col-md-12 col-form-label"
                        >
                          Slug <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="slug"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="slug"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.slug}
                            invalid={
                              validation.touched.slug && validation.errors.slug
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.slug &&
                            validation.errors.slug && (
                              <FormFeedback type="invalid">
                                {validation.errors.slug}
                              </FormFeedback>
                            )}
                          <small>{`https://www.../article/...`}</small>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="modelDetail"
                          className="col-md-12 col-form-label"
                        >
                          Detail
                        </label>
                        <div className="col-md-12">
                          <Editor
                            apiKey="dskur6ribo18yblfafofao54ygk88ts7g4js5o61q6w99vyg"
                            init={{
                              plugins: [
                                // Core editing features
                                "anchor",
                                "autolink",
                                "charmap",
                                "codesample",
                                "emoticons",
                                "image",
                                "link",
                                "lists",
                                "media",
                                "searchreplace",
                                "table",
                                "visualblocks",
                                "wordcount",
                                // Your account includes a free trial of TinyMCE premium features
                                // Try the most popular premium features until Sep 27, 2024:
                                "checklist",
                                "mediaembed",
                                "casechange",
                                "export",
                                "formatpainter",
                                "pageembed",
                                "a11ychecker",
                                "tinymcespellchecker",
                                "permanentpen",
                                "powerpaste",
                                "advtable",
                                "advcode",
                                "editimage",
                                "advtemplate",
                                "ai",
                                "mentions",
                                "tinycomments",
                                "tableofcontents",
                                "footnotes",
                                "mergetags",
                                "autocorrect",
                                "typography",
                                "inlinecss",
                                "markdown",
                              ],
                              toolbar:
                                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                              tinycomments_mode: "embedded",
                              tinycomments_author: "Author name",
                              mergetags_list: [
                                { value: "First.Name", title: "First Name" },
                                { value: "Email", title: "Email" },
                              ],
                              ai_request: (request, respondWith) =>
                                respondWith.string(() =>
                                  Promise.reject(
                                    "See docs to implement AI Assistant"
                                  )
                                ),
                            }}
                            initialValue={validation.values.contentDetail}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-3 mb-3">
                        <label
                          htmlFor="contentStatus"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="contentStatus"
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(
                              STATUS_OPTION,
                              `${validation.values.contentStatus}`
                            )}
                            onChange={value =>
                              validation.setFieldValue("contentStatus", value.id)
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${
                              validation.touched.contentStatus &&
                              validation.errors.contentStatus
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.contentStatus &&
                            validation.errors.contentStatus && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.contentStatus}
                              </div>
                            )}
                          {validation.touched.contentStatus &&
                            validation.errors.contentStatus && (
                              <FormFeedback type="invalid">
                                {validation.errors.contentStatus}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div
                  className="mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "8px",
                  }}
                >
                  {pageView == "view" ? (
                    <>
                      <button
                        className="btn btn-primary w-md"
                        onClick={() => navigate(`/article`)}
                      >
                        BACK
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-primary w-md">
                        SAVE
                      </button>
                      <button
                        className="btn btn-secondary w-md"
                        onClick={() => navigate(`/article`)}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

FormTemplatePage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FormTemplatePage)
