import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// TS Route
import UserList from "../pages/Systemsettings/UserList"
import UserAdd  from "../pages/Systemsettings/UserAdd"
import UserEdit from "../pages/Systemsettings/UserEdit"

// Questionaire Page
import QuestionaireCategoryList from "pages/Questionaire/CategoryList";
import QuestionaireCategoryCreate from "pages/Questionaire/QuestionaireCategory";
import QuestionaireCategoryEdit from "pages/Questionaire/QuestionaireCategoryEdit";

import QuestionaireList from "pages/Questionaire/QuestionaireList";
import QuestionaireCreate from "pages/Questionaire/QuestionaireCreate";
import QuestionaireEdit from "pages/Questionaire/QuestionaireEdit";


// Frontweb
import QuestionnaireFrontweb from "pages/Frontweb/QuestionnaireFrontweb";
import BannerPlatformList from "pages/BannerPlatform/BannerPlatformList";
import BannerPlatformTemplate from "pages/BannerPlatform/BannerPlatformTemplate";
import BannerPageList from "pages/BannerPage/BannerPageList";
import BannerPageTemplate from "pages/BannerPage/BannerPageTemplate";
import BannerList from "pages/BannerList/BannerList";
import BannerListTemplate from "pages/BannerList/BannerListTemplate";

// Survey page
import SurveyListPage from "pages/Survey/List";
import SurveyFormTemplatePage from "pages/Survey/FormTemplate";

// Customer page
import CustomerListPage from "pages/Customer/List";
import CustomerFormTemplatePage from "pages/Customer/FormTemplate";

// Point page
import PointTypeListPage from "pages/Point/Type/List";
import PointTypeFormTemplatePage from "pages/Point/Type/FormTemplate";
import PointConfigListPage from "pages/Point/Config/List";
import PointConfigFormTemplatePage from "pages/Point/Config/FormTemplate";
import PointSpecialEventConfigListPage from "pages/Point/SpecialEventConfig/List";
import PointSpecialEventConfigFormTemplatePage from "pages/Point/SpecialEventConfig/FormTemplate";

// Article page
import ArticleListPage from "pages/Article/List";
import ArticleFormTemplatePage from "pages/Article/FormTemplate";

// Product page
import ProductModelListPage from "pages/Product/Model/List";
import ProductModelFormTemplatePage from "pages/Product/Model/FormTemplate";
import ProductCategoryListPage from "pages/Product/Category/List";
import ProductCategoryFormTemplatePage from "pages/Product/Category/FormTemplate";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/systemsettings/usersetting", component: <UserList /> },
  { path: "/systemsettings/usersetting/useradd",  component: <UserAdd /> },
  { path: "/systemsettings/usersetting/useredit/:userId", component: <UserEdit /> },

  // for AI Shopping
  { path: "/questionnaire", component: <QuestionaireCategoryList /> },
  { path: "/questionnaire/category", component: <QuestionaireCategoryList /> },
  { path: "/questionnaire/category/create", component: <QuestionaireCategoryCreate /> },
  { path: "/questionnaire/category/edit/:categoryId", component: <QuestionaireCategoryEdit /> },

  { path: "/questionnaire/list/:categoryName", component: <QuestionaireList /> },
  // { path: "/questionnaire/create/:categoryId", component: <QuestionaireCreate /> },
  { path: "/questionnaire/create", component: <QuestionaireCreate /> },
  // { path: "/questionnaire/edit/:oldCategoryId/:newCategoryId", component: <QuestionaireEdit /> },
  { path: "/questionnaire/edit/:categoryId", component: <QuestionaireEdit /> },



  // for Banner
  { path: "/banner/banner-platform", component: <BannerPlatformList /> },
  { path: "/banner/banner-platform/create", component: <BannerPlatformTemplate pageView="create" /> },
  { path: "/banner/banner-platform/edit/:id", component: <BannerPlatformTemplate pageView="update" /> },
  { path: "/banner/banner-platform/view/:id", component: <BannerPlatformTemplate pageView="view" /> },

  { path: "/banner/banner-page", component: <BannerPageList /> },
  { path: "/banner/banner-page/create", component: <BannerPageTemplate pageView="create" /> },
  { path: "/banner/banner-page/edit/:id", component: <BannerPageTemplate pageView="update" /> },
  { path: "/banner/banner-page/view/:id", component: <BannerPageTemplate pageView="view" /> },

  { path: "/banner/banner-list", component: <BannerList /> },
  { path: "/banner/banner-list/create", component: <BannerListTemplate pageView="create" /> },
  { path: "/banner/banner-list/edit/:id", component: <BannerListTemplate pageView="update" /> },
  { path: "/banner/banner-list/view/:id", component: <BannerListTemplate pageView="view" /> },

  // { path: "/banner/banner-platform/edit/:id", component: <BannerPlatformTemplate pageView="update" /> },
  // { path: "/banner/banner-platform/view/:id", component: <BannerPlatformTemplate pageView="view" /> },

  /* Survey route */
  { path: "/survey", component: <SurveyListPage /> },
  { path: "/survey/create", component: <SurveyFormTemplatePage pageView="create" /> },
  { path: "/survey/edit/:id", component: <SurveyFormTemplatePage pageView="update" /> },
  /* End Survey route */

  /* Customer route */
  { path: "/customer", component: <CustomerListPage /> },
  { path: "/customer/view/:id", component: <CustomerFormTemplatePage pageView="view" /> },
  /* End Customer route */

  /* Point route */
  { path: "/point/type", component: <PointTypeListPage /> },
  { path: "/point/type/create", component: <PointTypeFormTemplatePage pageView="create" /> },
  { path: "/point/type/edit/:id", component: <PointTypeFormTemplatePage pageView="update" /> },
  { path: "/point/config", component: <PointConfigListPage /> },
  { path: "/point/config/create", component: <PointConfigFormTemplatePage pageView="create" /> },
  { path: "/point/config/edit/:id", component: <PointConfigFormTemplatePage pageView="update" /> },
  { path: "/point/special-event-config", component: <PointSpecialEventConfigListPage /> },
  { path: "/point/special-event-config/create", component: <PointSpecialEventConfigFormTemplatePage pageView="create" /> },
  { path: "/point/special-event-config/edit/:id", component: <PointSpecialEventConfigFormTemplatePage pageView="update" /> },
  /* End Point route */

  /* Article route */
  { path: "/article", component: <ArticleListPage /> },
  { path: "/article/create", component: <ArticleFormTemplatePage pageView="create" /> },
  { path: "/article/edit/:id", component: <ArticleFormTemplatePage pageView="update" /> },
  /* End Article route */

  /* Product route */
  { path: "/product/model", component: <ProductModelListPage /> },
  { path: "/product/model/create", component: <ProductModelFormTemplatePage pageView="create" /> },
  { path: "/product/model/edit/:id", component: <ProductModelFormTemplatePage pageView="update" /> },
  { path: "/product/category", component: <ProductCategoryListPage /> },
  { path: "/product/category/create", component: <ProductCategoryFormTemplatePage pageView="create" /> },
  { path: "/product/category/edit/:id", component: <ProductCategoryFormTemplatePage pageView="update" /> },
  /* End Product route */

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  {path: "/make/questionnaire/:questionnaireUUID", component: <QuestionnaireFrontweb />}
];

export { authProtectedRoutes, publicRoutes };
