import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Badge,
} from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";

import { get } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner";
import { DeleteBannerPage, SearchBannerPage } from "services/api/module/BannerPage";

import ModalContext from "../../contexts/ModalContext";
import { DropdownBannerPlatform } from "services/api/module/Dropdown";

const RowStatus = ({ status }) => {
    switch (String(status)) {
        case "0":
            return <Badge className="bg-danger">InActive</Badge>;
        case "1":
            return <Badge className="bg-success">Active</Badge>;
        default:
            return <Badge className="bg-success">{status}</Badge>;
    }
};

const BannerPageList = props => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    // OTHER STATE
    const { categoryName } = useParams();
    const navigate = useNavigate();

    // LOCAL STATE
    const [contentList, setContentList] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)
    const [selectedContentId, setSelectedContentId] = useState([])

    // DROPDOWN
    const [dropdownFilter, setDropdownFilter] = useState([])

    const onDeleteRow = (ids = [], name = '') => {
        // alert('onDeleteRow'+ JSON.stringify(ids))
        mAlert.info({
            type: "confrim",
            title: "Alert Confrim",
            subTitle: `Are you sure Delete ${name} ?`,
            mode: "red",
            content: "",
            onClose: () => {},
            onYes: () => { 
                const payload = { bannerPageIds: ids};
                API.fetchDeleteContentList(payload)
            },
            onNo: () => { console.log("Cancel") },
        })
    }

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if ( findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
            updateState = []
            } else {
            updateState = option.map(item => item.id)
            }
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        return updateState
    };

    const columns = useMemo(
        () => [
            {
                id: "colcheckbox",
                header: (cellProps) => {
                    const row = cellProps?.row?.original
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Input 
                                type={`checkbox`} 
                                checked={contentList.length === selectedContentId.length}
                                onClick={() => {
                                    let updateData = onSelectionChange(row, selectedContentId, contentList, "all")
                                    setSelectedContentId(updateData)
                                }}
                                readOnly
                            />
                        </div>
                )},
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return (
                        <>
                            <div id={cellProps.row.id} style={{ display: "flex", justifyContent: "center" }}>
                                <Input 
                                    type={`checkbox`} 
                                    value={cellProps.row.original.id} 
                                    checked={selectedContentId.includes(row.id)} 
                                    onClick={() => {
                                        let updateData = onSelectionChange(row, selectedContentId, contentList)
                                        setSelectedContentId(updateData)
                                    }}
                                    readOnly
                                />
                            </div>
                        </>
                    )
                }
            },
            {
                header: '#',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                { (parseInt(cellProps.row.id) + 1) }
                            </div>
                        </>
                    )
                }
            },
            {
                header: 'Banner Group Page',
                accessorKey: 'pageName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Platform',
                accessorKey: 'bannerPlatformId',
                enableColumnFilter: false,
                enableSorting: true,
                filterFn: 'optionFN',
                cell: (cellProps) => {
                    const { bannerPlatformName = '' } = cellProps?.row?.original
                    return <div>{bannerPlatformName}</div>
                }
            },
            {
                header: 'Width',
                accessorKey: 'bannerWidth',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Height',
                accessorKey: 'bannerHeight',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => <RowStatus status={cellProps.row.original.status} />
            },
            {
                header: 'Tools',
                accessorKey: 'user_id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let { id, pageName } = cellProps?.row?.original ?? {};
                    return (
                        <>
                            {checkScope('systemsettings:usersetting:view') && <Link className={`btn btn-info`} to={`/banner/banner-page/view/${id}`}><i className={`bx bx-show-alt`}></i></Link>}
                            &nbsp;
                            {checkScope('systemsettings:usersetting:update') && <Link className={`btn btn-warning`} to={`/banner/banner-page/edit/${id}`}><i className={`bx bx-edit-alt`}></i></Link>}
                            &nbsp;
                            {checkScope('systemsettings:usersetting:delete') && <a className={`btn btn-danger`} onClick={() => onDeleteRow([id], pageName)}><i className={`bx bx-trash`}></i></a>}
                        </>
                    )
                }
            },
        ],
        [selectedContentId, contentList]
    );

    const API = {
        fetchContentList: async () => {
            try {
                const response = await SearchBannerPage({})
                const resData = response?.data ?? []
                setContentList(resData.data);
            } catch (e) {
                console.log(e)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                    //  alert("onClose")
                    },
                })
            }
        },
        fetchDeleteContentList: async (payload) => {
            try {
                const response = await DeleteBannerPage({ data: payload })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Delete Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                    //  alert("onClose")
                    },
                })
            }
        }
        
    }

    const fetchAPI = () => {
        const payload = {}
        API.fetchContentList(payload)
    }
    const fetchAPIDropDown = () => {

        let _dropdownFilter = [
            { label: 'bannerPlatform', key: 'bannerPlatformId', option: [] },
        ]
        const request1 = DropdownBannerPlatform({});

        Promise.all([request1])
        .then(results => {
            let DEF_DROPDOWN = [ { id: '', name: 'NO SELECT' } ]
            let updateDropDown = _dropdownFilter.map((item, i) => {
                const resData = results[i]?.data ?? {};
                const tmpOption = resData?.data ?? []
                item.option = [ ...DEF_DROPDOWN, ...tmpOption]
                return item
            })
            // updateDropDown = [ ...DEF_DROPDOWN, ...updateDropDown]
            setDropdownFilter(updateDropDown)
        })
        .catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        setContentListLoading(true)
        fetchAPIDropDown()
        fetchAPI()
    }, []);

    useEffect(() => {
        if (contentList.length) {
            setContentListLoading(false)
        }
    }, [contentList])

    //meta title
    document.title = "Banner Page | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Banner" breadcrumbItem="Banner Page" />
                {
                    contentListLoading ? <Spinners setLoading={setContentListLoading} />
                    : 
                        <TableContainer
                            isAddButton={true}
                            addRowLink={`/create`}
                            
                            columns={columns}
                            data={contentList || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder="Search"
                            pagination="pagination"
                            paginationWrapper='dataTables_paginate paging_simple_numbers'
                            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            rootMenu="systemsettings"
                            subMenu="usersetting"
                            isCustomPageSize={true}
                            isSelected={selectedContentId.length > 0}
                            onClearSelected={() => setSelectedContentId([])}
                            onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
                            isExportButton={true}
                            exportOption={{
                                filename: "banner-platform",
                                dateColumns: []
                            }}
                            dropdownFilter={dropdownFilter}
                        />
                }
            </div>
        </div>
    );
}

BannerPageList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(BannerPageList)
