import React, { useEffect, useState, useMemo, useContext } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
} from "reactstrap"
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import Spinners from "../../../components/Common/Spinner"
import TableContainer from "../../../components/Common/TableContainer"

import ModalContext from "../../../contexts/ModalContext"

import { checkScope } from "helpers/utility_helper"

import moment from "moment"

import {
  getPointType,
  deletePointType,
} from "../../../services/api/module/Point"

const RowStatus = ({ status }) => {
  switch (status) {
    case "active":
      return <Badge className="bg-success"> Active </Badge>

    case "inactive":
      return <Badge className="bg-warning"> Inactive </Badge>

    case "deleted":
      return <Badge className="bg-danger"> Deleted </Badge>

    default:
      return <>{`-`}</>
  }
}

const PointTypeListPage = () => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Api */
  const API = {
    getContentList: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await getPointType({ params: payload })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    delContentList: async payload => {
      try {
        const response = await deletePointType({ data: payload })
        const resData = response.data

        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Delete Data Success",
            content: "",
            onClose: () => {
              fetchAPI()
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }
    },
  }
  /* End Api */

  /* First event render */
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])

  const fetchAPI = () => {
    setContentListLoading(true)

    API.getContentList({}).then(_rs => {
      // console.log("_rs >>", _rs)

      if (_rs.status === true) {
        setContentList(_rs.result)
        setContentListLoading(false)
      }
    })
  }

  useEffect(() => {
    fetchAPI()
  }, [])
  /* End First event render */

  useEffect(() => {
    // console.log("contentList >>", contentList);
  }, [contentList, contentListLoading])

  /* Set table column */
  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }}
                  readOnly
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      {
        header: "Type",
        accessorKey: "typeName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created At",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps =>
          moment(cellProps.row.original.createdAt).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
      },
      {
        header: "Updated At",
        accessorKey: "updatedAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps =>
          cellProps.row.original.updatedAt !== null
            ? moment(cellProps.row.original.updatedAt).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "-",
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => <RowStatus status={cellProps.row.original.status} />,
      },
      {
        header: "Tools",
        accessorKey: "user_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          let { id, platformName } = cellProps?.row?.original ?? {}
          return (
            <>
              {/* {checkScope("systemsettings:usersetting:view") && (
                <Link
                  className={`btn btn-info`}
                  to={`/point/type/view/${id}`}
                >
                  <i className={`bx bx-show-alt`}></i>
                </Link>
              )}
              &nbsp; */}
              {checkScope("systemsettings:usersetting:update") && (
                <Link
                  className={`btn btn-warning`}
                  to={`/point/type/edit/${id}`}
                >
                  <i className={`bx bx-edit-alt`}></i>
                </Link>
              )}
              {/* &nbsp;
              {checkScope("systemsettings:usersetting:delete") && (
                <a
                  className={`btn btn-danger`}
                  onClick={() => onDeleteRow([id], platformName)}
                >
                  <i className={`bx bx-trash`}></i>
                </a>
              )} */}
            </>
          )
        },
      },
    ],
    [selectedContentId, contentList]
  )
  /* End Set table column */

  /* Handle delete row */
  const onDeleteRow = (ids = [], name = "") => {
    // console.log('onDeleteRow'+ JSON.stringify(ids))

    mAlert.info({
      type: "confrim",
      title: "Alert Confrim",
      subTitle: `Are you sure Delete ${name} ?`,
      mode: "red",
      content: "",
      onClose: () => {},
      onYes: () => {
        const payload = { ids: ids }
        API.delContentList(payload)
      },
      onNo: () => {
        // console.log("Cancel")
      },
    })
  }
  /* End handle delete row */

  /* Handle selection row */
  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }
  /* End Handle selection row */

  //meta title
  document.title = "Point Type | " + process.env.REACT_APP_CMS_TITLE

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Survey" breadcrumbItem="List" /> */}
          <Breadcrumbs
            title={`Point Type List`}
            breadcrumbItems={[
              { title: "Point Type", link: "point/type" },
              { title: "List" },
            ]}
          />

          {contentListLoading ? (
            <Spinners setLoading={setContentListLoading} />
          ) : (
            <TableContainer
              isAddButton={false}
              addRowLink={`/create`}
              columns={columns}
              data={contentList || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search"
              pagination="pagination"
              paginationWrapper="dataTables_paginate paging_simple_numbers"
              tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              rootMenu="systemsettings"
              subMenu="usersetting"
              isCustomPageSize={true}
              isSelected={selectedContentId.length > 0}
              onClearSelected={() => setSelectedContentId([])}
              onDeleleSelected={() =>
                // onDeleteRow(_.cloneDeep(selectedContentId))
                alert("Point Type Can't Delete!")
              }
              isExportButton={true}
              exportOption={{
                filename: "pointType",
                dateColumns: [],
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

PointTypeListPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PointTypeListPage)
