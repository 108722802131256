import React, { useEffect, useState, useContext  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateBannerPlatform, GetBannerPlatformById, UpdateBannerPlatform } from "services/api/module/BannerPlatform"
import ModalContext from "../../contexts/ModalContext";

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const BannerPlatformTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qBannerPlatformId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    platformName: Yup.string().max(100).required("Please Enter Platform Name"),
    description: Yup.string().max(500).required("Please Enter Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      platformName: "",
      description: "",
      status: ""
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ BannerPlatformCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        platformName: values.platformName,
        description: values.description,
        status: Number(values.status)
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateBannerPlatform(payload)
      } else {
        API.fetchCreateBannerPlatform(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("platformName", resData.platformName)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetBannerPlatformById: async (payload) => {
      try {
          const response = await GetBannerPlatformById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateBannerPlatform: async (payload) => {
      try {
          const response = await CreateBannerPlatform({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/banner/banner-platform')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateBannerPlatform: async (payload) => {
      try {
          const response = await UpdateBannerPlatform({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/banner/banner-platform')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qBannerPlatformId }
      API.fetchGetBannerPlatformById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Banner Platform | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Banner Platform"}
            breadcrumbItems={[
              { title: "Banner Platform", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Banner Platform Infomation</h5>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Platform Name
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="platformName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="platformName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.platformName}
                            invalid={validation.touched.platformName && validation.errors.platformName ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.platformName && validation.errors.platformName && (
                              <FormFeedback type="invalid">
                                {validation.errors.platformName}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="description"
                            className="form-control"
                            type="textarea"
                            rows="3"
                            placeholder=""
                            name="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={ validation.touched.description && validation.errors.description ? true: false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.description && validation.errors.description && (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                          { 
                            validation.touched.status && validation.errors.status && (
                              <FormFeedback type="invalid">
                                {validation.errors.status}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/banner/banner-platform')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/banner/banner-platform')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(BannerPlatformTemplate)
