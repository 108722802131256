import { get, post, put } from "../Config"

export function getContent({ params = {} }) {
  return get({ url: `/v1/admin/article`, params })
}
export function createContent({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/article/create`, params, data })
}
export function updateContent({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/article/update/${id}`, params, data })
}
export function deleteContent({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/article/delete`, params, data })
}
