import React, { useEffect, useState, useMemo, useContext, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  CardHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import Select from "react-select"
import { Editor } from "@tinymce/tinymce-react"

import ModalContext from "../../../contexts/ModalContext"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import fn_helper from "helpers/fn_helper"

import Sortable from "sortablejs"


import {
  createContent,
  getContent,
  updateContent,
} from "../../../services/api/module/Article"

import img_xsr155_01 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-01.png"
import img_xsr155_02 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-02.png"
import img_xsr155_03 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-03.png"
import img_xsr155_tech_01 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-tech-01.jpg"
import img_xsr155_tech_02 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-tech-02.jpg"
import img_xsr155_feature_01 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-feature-01.jpg"
import img_xsr155_feature_02 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-feature-02.jpg"
import img_xsr155_feature_03 from "../../../assets/images/demo/yamaha-motor/xsr155-2023-feature-03.jpg"

const CATEGORY_OPTION = [
  { id: 1, name: "รถมอเตอร์ไซค์ต่ำกว่า 400 cc." },
  { id: 2, name: "รถบิ๊กไบค์" },
  { id: 3, name: "ผลิตภัณฑ์ทางน้ำ" },
  { id: 4, name: "รถกอล์ฟ" },
  { id: 5, name: "ออนไลน์ช้อป" },
]

const SUB_CATEGORY_OPTION = [
  { id: 1, name: "ระบบออโตเมติก" },
  { id: 2, name: "รถครอบครัว" },
  { id: 3, name: "สปอร์ต" },
  { id: 4, name: "สปอร์ตเฮอริเทจ" },
  { id: 5, name: "MT Series" },
]

const STATUS_OPTION = [
  { id: "inactive", name: "Inactive" },
  { id: "active", name: "Active" },
]

const FormTemplatePage = ({ pageView = "create" }) => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Navigate */
  const navigate = useNavigate()
  /* End Navigate */

  /* Api */
  const API = {
    createContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await createContent({ data: payload })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate(`/product/model`)
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    getContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await getContent({
          params: payload,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    updateContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await updateContent({
          data: payload,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate(`/product/model`)
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
  }
  /* End Api */

  /* Form Validation */
  const [dataContent, setDataContent] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      modelId:
        dataContent !== null && dataContent.modelId ? dataContent.modelId : "",
      modelFullName:
        dataContent !== null && dataContent.modelFullName
          ? dataContent.modelFullName
          : pageView === "update"
          ? "XSR155 [2023]"
          : "",
      categoryId:
        dataContent !== null && dataContent.categoryId
          ? dataContent.categoryId
          : pageView === "update"
          ? 1
          : "",
      subCategoryId:
        dataContent !== null && dataContent.subCategoryId
          ? dataContent.subCategoryId
          : pageView === "update"
          ? 4
          : "",
      slug:
        dataContent !== null && dataContent.slug
          ? dataContent.slug
          : pageView === "update"
          ? "xsr155-2023"
          : "",
      modelDetail:
        dataContent !== null && dataContent.modelDetail
          ? dataContent.modelDetail
          : pageView === "update"
          ? "Yamaha XSR155 ปี 2023 คุณค่าวิถีดั้งเดิม ถูกเชื่อมโยงกับเทคโนโลยีใหม่ ตำนานถูกซ้อนอยู่เบื้องหลังความประณีต คัสต้อมได้ในแบบที่คุณเป็น เพื่อเติมเต็มชีวิตที่คุณใช้ Inspired By The Past, Built For The Future ขับเคลื่อนวิถีเดิม... ให้ชีวิตไปได้ไกลกว่า"
          : "",
      price:
        dataContent !== null && dataContent.price !== null
          ? dataContent.price
          : pageView === "update"
          ? 96600
          : 0,
      modelStatus: dataContent !== null ? dataContent.modelStatus : "active",
      thumbnail:
        dataContent !== null && dataContent.thumbnail
          ? dataContent.thumbnail
          : pageView === "update"
          ? img_xsr155_01
          : "",
      gallery:
        dataContent !== null && dataContent.gallery
          ? dataContent.gallery
          : pageView === "update"
          ? [
              { id: 1, image: img_xsr155_01 },
              { id: 2, image: img_xsr155_02 },
              { id: 3, image: img_xsr155_03 },
            ]
          : [],
      technology:
        dataContent !== null && dataContent.technology
          ? dataContent.technology
          : pageView === "update"
          ? [
              {
                id: 1,
                title: "เครื่องยนต์ 155 CC VVA",
                desc: "เครื่องยนต์ 155 CC VVA แรงเร้าใจไปได้ไกลกว่า ในทุกประสบการณ์การขับขี่",
                image: img_xsr155_tech_01,
                status: "active",
              },
              {
                id: 2,
                title: "Assist & Slipper Clutch",
                desc: "ระบบคลัตช์ที่ช่วยลดแรงกระชากของล้อหลังขณะลดเกียร์ เพิ่มความมั่นใจในทุกการขับขี่",
                image: img_xsr155_tech_02,
                status: "active",
              },
            ]
          : [],
      feature:
        dataContent !== null && dataContent.feature
          ? dataContent.feature
          : pageView === "update"
          ? [
              {
                id: 1,
                title: "LED HEADLIGHT",
                desc: "ไฟหน้า LED ทรงกลมสไตล์ Heritage ผสานเทคโนโลยีที่เหนือชั้น สว่างชัดเจนในการขับขี่ เพิ่มความปลอดภัยในทุกเส้นทาง",
                image: img_xsr155_feature_01,
                status: "active",
              },
              {
                id: 2,
                title: "TIMELESS QUALITY IMPRESSION",
                desc: "ถังน้ำมันทรงหยดน้ำ พร้อม Knee Grip ให้คุณกับรถเป็นเสมือนหนึ่งเดียวกัน",
                image: img_xsr155_feature_02,
                status: "active",
              },
              {
                id: 3,
                title: "LED TAIL LIGHT",
                desc: "ไฟท้าย LED ทรงกลมสไตล์ Heritage ส่องสว่างชัดเจน สอดรับกับดีไซน์ของตัวรถ",
                image: img_xsr155_feature_03,
                status: "active",
              },
            ]
          : [],
    },
    validationSchema: Yup.object({
      modelFullName: Yup.string().required("Please Enter Full Name"),
      modelStatus: Yup.string().required("Please Select Status"),
    }),
    onSubmit: values => {
      console.log("🚀 ~ Survey ~ values:", values)
      let payload = {
        modelFullName: values.modelFullName,
        modelStatus: values.modelStatus,
      }
      if (typeof values.modelId !== "undefined") {
        payload = {
          ...payload,
          modelId: values.modelId,
        }
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      // if (pageView === "update") {
      //   API.updateContent(payload)
      // } else {
      //   API.createContent(payload)
      // }
    },
  })
  /* End Form Validation */

  /* First event render */
  const qParams = useParams()
  const qContentId = qParams.id

  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      // API.getContent({ id: qContentId }).then(_rs => {
      //   // console.log(_rs)
      //   if (_rs.status === true) {
      //     if (_rs.result.length > 0) {
      //       setDataContent(_rs.result[0])
      //     } else {
      //       navigate(`/product/model`)
      //     }
      //   }
      // })
    }
  }, [])
  /* End First event render */

  // const [thumbnailImage, setThumbnailImage] = useState(null)

  const handleThumbnailChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]
    reader.onloadend = () => {
      // setThumbnailImage(reader.result)
      validation.setFieldValue("thumbnail", reader.result)
    }
    reader.readAsDataURL(file)
  }

  useEffect(() => {
    const sortable = Sortable.create(document.getElementById("galleryImage"), {
      animation: 150,
      onEnd: evt => {
        const newInputs = Array.from(validation.values.gallery)
        const [removed] = newInputs.splice(evt.oldIndex, 1)
        newInputs.splice(evt.newIndex, 0, removed)
        validation.setFieldValue("gallery", newInputs)
      },
    })

    return () => {
      sortable.destroy()
    }
  }, [validation.values.galley])

  const [openTechAccordion, setOpenTechAccordion] = useState("2")

  const toggleTechAccordion = id => {
    if (openTechAccordion.includes(id)) {
      setOpenTechAccordion(openTechAccordion.filter(i => i !== id))
    } else {
      setOpenTechAccordion([...openTechAccordion, id])
    }
  }

  useEffect(() => {
    const sortable = Sortable.create(document.getElementById("techAccordion"), {
      animation: 150,
      onEnd: evt => {
        const newInputs = Array.from(validation.values.technology)
        const [removed] = newInputs.splice(evt.oldIndex, 1)
        newInputs.splice(evt.newIndex, 0, removed)
        validation.setFieldValue("technology", newInputs)
      },
    })

    return () => {
      sortable.destroy()
    }
  }, [validation.values.technology])

  const [openFeatureAccordion, setOpenFeatureAccordion] = useState("2")

  const toggleFeatureAccordion = id => {
    if (openFeatureAccordion.includes(id)) {
      setOpenFeatureAccordion(openFeatureAccordion.filter(i => i !== id))
    } else {
      setOpenFeatureAccordion([...openFeatureAccordion, id])
    }
  }

  useEffect(() => {
    const sortable = Sortable.create(
      document.getElementById("featureAccordion"),
      {
        animation: 150,
        onEnd: evt => {
          const newInputs = Array.from(validation.values.feature)
          const [removed] = newInputs.splice(evt.oldIndex, 1)
          newInputs.splice(evt.newIndex, 0, removed)
          validation.setFieldValue("feature", newInputs)
        },
      }
    )

    return () => {
      sortable.destroy()
    }
  }, [validation.values.feature])

  //meta title
  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `Product Model | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={`${PAGE_VIEW} Product Model`}
            breadcrumbItems={[
              { title: "Product Model", link: "product/model" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <div className="card-title">Basic Infomation</div>
                      <p className="card-title-desc mb4">
                        Fill all information below
                      </p>
                    </Row>
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="modelFullName"
                          className="col-md-12 col-form-label"
                        >
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="modelFullName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="modelFullName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.modelFullName}
                            invalid={
                              validation.touched.modelFullName &&
                              validation.errors.modelFullName
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.modelFullName &&
                            validation.errors.modelFullName && (
                              <FormFeedback type="invalid">
                                {validation.errors.modelFullName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="modelShortName"
                          className="col-md-12 col-form-label"
                        >
                          Short Name
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="modelShortName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="modelShortName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.modelShortName}
                            invalid={
                              validation.touched.modelShortName &&
                              validation.errors.modelShortName
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.modelShortName &&
                            validation.errors.modelShortName && (
                              <FormFeedback type="invalid">
                                {validation.errors.modelShortName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="price"
                          className="col-md-12 col-form-label"
                        >
                          Price <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="price"
                            className="form-control"
                            type="number"
                            name="price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price}
                            invalid={
                              validation.touched.price &&
                              validation.errors.price
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                            min="0"
                          />
                          {validation.touched.price &&
                            validation.errors.price && (
                              <FormFeedback type="invalid">
                                {validation.errors.price}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="categoryId"
                          className="col-md-12 col-form-label"
                        >
                          Category <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="categoryId"
                            placeholder="Select Category"
                            value={fn_helper.FNFORM.getObjectValue(
                              CATEGORY_OPTION,
                              `${validation.values.categoryId}`
                            )}
                            onChange={value =>
                              validation.setFieldValue("categoryId", value.id)
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={CATEGORY_OPTION}
                            className={`select2-selection ${
                              validation.touched.categoryId &&
                              validation.errors.categoryId
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.categoryId &&
                            validation.errors.categoryId && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.categoryId}
                              </div>
                            )}
                          {validation.touched.categoryId &&
                            validation.errors.categoryId && (
                              <FormFeedback type="invalid">
                                {validation.errors.categoryId}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="subCategoryId"
                          className="col-md-12 col-form-label"
                        >
                          Sub Category <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="subCategoryId"
                            placeholder="Select Category"
                            value={fn_helper.FNFORM.getObjectValue(
                              SUB_CATEGORY_OPTION,
                              `${validation.values.subCategoryId}`
                            )}
                            onChange={value =>
                              validation.setFieldValue(
                                "subCategoryId",
                                value.id
                              )
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={SUB_CATEGORY_OPTION}
                            className={`select2-selection ${
                              validation.touched.subCategoryId &&
                              validation.errors.subCategoryId
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.subCategoryId &&
                            validation.errors.subCategoryId && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.subCategoryId}
                              </div>
                            )}
                          {validation.touched.subCategoryId &&
                            validation.errors.subCategoryId && (
                              <FormFeedback type="invalid">
                                {validation.errors.subCategoryId}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="slug"
                          className="col-md-12 col-form-label"
                        >
                          Slug <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="slug"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="slug"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.slug}
                            invalid={
                              validation.touched.slug && validation.errors.slug
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.slug &&
                            validation.errors.slug && (
                              <FormFeedback type="invalid">
                                {validation.errors.slug}
                              </FormFeedback>
                            )}
                          <small>{`https://www.yamaha-motor.co.th/product/รถมอเตอร์ไซค์ต่ำกว่า-400-cc/สปอร์ตเฮอริเทจ/xsr155-2023`}</small>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="modelDetail"
                          className="col-md-12 col-form-label"
                        >
                          Detail
                        </label>
                        <div className="col-md-12">
                          <Editor
                            apiKey="dskur6ribo18yblfafofao54ygk88ts7g4js5o61q6w99vyg"
                            init={{
                              plugins: [
                                // Core editing features
                                "anchor",
                                "autolink",
                                "charmap",
                                "codesample",
                                "emoticons",
                                "image",
                                "link",
                                "lists",
                                "media",
                                "searchreplace",
                                "table",
                                "visualblocks",
                                "wordcount",
                                // Your account includes a free trial of TinyMCE premium features
                                // Try the most popular premium features until Sep 27, 2024:
                                "checklist",
                                "mediaembed",
                                "casechange",
                                "export",
                                "formatpainter",
                                "pageembed",
                                "a11ychecker",
                                "tinymcespellchecker",
                                "permanentpen",
                                "powerpaste",
                                "advtable",
                                "advcode",
                                "editimage",
                                "advtemplate",
                                "ai",
                                "mentions",
                                "tinycomments",
                                "tableofcontents",
                                "footnotes",
                                "mergetags",
                                "autocorrect",
                                "typography",
                                "inlinecss",
                                "markdown",
                              ],
                              toolbar:
                                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                              tinycomments_mode: "embedded",
                              tinycomments_author: "Author name",
                              mergetags_list: [
                                { value: "First.Name", title: "First Name" },
                                { value: "Email", title: "Email" },
                              ],
                              ai_request: (request, respondWith) =>
                                respondWith.string(() =>
                                  Promise.reject(
                                    "See docs to implement AI Assistant"
                                  )
                                ),
                            }}
                            initialValue={validation.values.modelDetail}
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="thumbnail"
                          className="col-md-12 col-form-label"
                        >
                          Thumbnail <span className="text-danger">*</span>
                        </label>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute bottom-0 end-0">
                              <Label htmlFor="thumbnail" className="mb-0">
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-success border rounded-circle text-mute cursor-pointer shadow font-size-16">
                                    <i className="bx bx-plus-medical"></i>
                                  </div>
                                </div>
                              </Label>
                              <input
                                className="form-control d-none"
                                id="thumbnail"
                                name="thumbnail"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={handleThumbnailChange}
                              />
                            </div>
                            <div
                              className="avatar-lg shadow-lg"
                              style={{
                                width: "18rem",
                                height: "18rem",
                              }}
                            >
                              <div className="avatar-title bg-white">
                                <img
                                  src={validation.values.thumbnail || ""}
                                  alt=""
                                  className="avatar-md h-auto w-100"
                                />
                              </div>
                            </div>
                          </div>
                          {validation.touched.thumbnail &&
                          validation.errors.thumbnail ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.thumbnail}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-3 mb-3">
                        <label
                          htmlFor="modelStatus"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="modelStatus"
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(
                              STATUS_OPTION,
                              `${validation.values.modelStatus}`
                            )}
                            onChange={value =>
                              validation.setFieldValue("modelStatus", value.id)
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${
                              validation.touched.modelStatus &&
                              validation.errors.modelStatus
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.modelStatus &&
                            validation.errors.modelStatus && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.modelStatus}
                              </div>
                            )}
                          {validation.touched.modelStatus &&
                            validation.errors.modelStatus && (
                              <FormFeedback type="invalid">
                                {validation.errors.modelStatus}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="card-title">
                          Gallery{" "}
                          <small
                            className="text-secondary"
                            style={{ fontWeight: "lighter" }}
                          >
                            {`(Drag and drop for sorting)`}
                          </small>
                        </div>
                        <a
                          className="btn btn-success"
                          onClick={() => console.log("addGalleryImage")}
                        >
                          Add Image
                        </a>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <div
                          className="text-center"
                          id="galleryImage"
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          {validation.values.gallery.map((item, index) => (
                            <div
                              className="position-relative d-inline-block"
                              key={item.id}
                            >
                              <div
                                className="avatar-lg shadow-lg"
                                style={{
                                  width: "18rem",
                                  height: "18rem",
                                }}
                              >
                                <div className="avatar-title bg-white">
                                  <img
                                    className="avatar-md h-auto w-100"
                                    src={item.image}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="card-title">
                          Technology{" "}
                          <small
                            className="text-secondary"
                            style={{ fontWeight: "lighter" }}
                          >
                            {`(Drag and drop for sorting)`}
                          </small>
                        </div>
                        <a
                          className="btn btn-success"
                          onClick={() => console.log("addGalleryImage")}
                        >
                          Add Item
                        </a>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Accordion
                          open={openTechAccordion}
                          toggle={toggleTechAccordion}
                          stayopen="true"
                          id="techAccordion"
                          className="shadow-sm"
                        >
                          {validation.values.technology.map((item, index) => (
                            <AccordionItem key={item.id} data-id={item.id}>
                              <AccordionHeader targetId={`technology${index}`}>
                                {`${index + 1}. ${item.title}`}
                              </AccordionHeader>
                              <AccordionBody accordionId={`technology${index}`}>
                                <Row>
                                  <Col className="col-6">
                                    <Row>
                                      <Col className="col-12 mb-3">
                                        <div className="text-center">
                                          <div className="position-relative d-inline-block">
                                            <div className="position-absolute bottom-0 end-0">
                                              <Label
                                                htmlFor={`technology[${index}].image`}
                                                className="mb-0"
                                              >
                                                <div className="avatar-xs">
                                                  <div className="avatar-title bg-success border rounded-circle text-mute cursor-pointer shadow font-size-16">
                                                    <i className="bx bx-plus-medical"></i>
                                                  </div>
                                                </div>
                                              </Label>
                                              <input
                                                className="form-control d-none"
                                                name={`technology[${index}].image`}
                                                type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                // onChange={handleThumbnailChange}
                                              />
                                            </div>
                                            <div
                                              className="avatar-lg shadow-lg"
                                              style={{
                                                width: "18rem",
                                                height: "18rem",
                                              }}
                                            >
                                              <div className="avatar-title bg-white">
                                                <img
                                                  src={item.image || ""}
                                                  alt=""
                                                  className="avatar-md h-auto w-100"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {validation.touched.technology &&
                                            validation.touched.technology[
                                              index
                                            ] &&
                                            validation.errors.technology &&
                                            validation.errors.technology[index]
                                              ?.image && (
                                              <FormFeedback
                                                type="invalid"
                                                className="d-block"
                                              >
                                                {
                                                  validation.errors.technology[
                                                    index
                                                  ].image
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col className="col-6">
                                    <Row>
                                      <Col className="col-12 mb-3">
                                        <label
                                          htmlFor={`technology[${index}].title`}
                                          className="col-md-12 col-form-label"
                                        >
                                          Title
                                        </label>
                                        <div className="col-md-12">
                                          <Input
                                            type="text"
                                            name={`technology[${index}].title`}
                                            value={item.title}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            placeholder={``}
                                            invalid={
                                              !!(
                                                validation.touched.technology &&
                                                validation.touched.technology[
                                                  index
                                                ] &&
                                                validation.errors.technology &&
                                                validation.errors.technology[
                                                  index
                                                ]?.title
                                              )
                                            }
                                            disabled={pageView === "view"}
                                          />
                                          {validation.touched.technology &&
                                            validation.touched.technology[
                                              index
                                            ] &&
                                            validation.errors.technology &&
                                            validation.errors.technology[index]
                                              ?.title && (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.technology[
                                                    index
                                                  ].title
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                      <Col className="col-12 mb-3">
                                        <label
                                          htmlFor={`technology[${index}].desc`}
                                          className="col-md-12 col-form-label"
                                        >
                                          Description
                                        </label>
                                        <div className="col-md-12">
                                          <Input
                                            className="form-control"
                                            type="textarea"
                                            rows="3"
                                            placeholder=""
                                            name={`technology[${index}].desc`}
                                            value={item.desc}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                              !!(
                                                validation.touched.technology &&
                                                validation.touched.technology[
                                                  index
                                                ] &&
                                                validation.errors.technology &&
                                                validation.errors.technology[
                                                  index
                                                ]?.desc
                                              )
                                            }
                                            disabled={pageView === "view"}
                                          />
                                          {validation.touched.technology &&
                                            validation.touched.technology[
                                              index
                                            ] &&
                                            validation.errors.technology &&
                                            validation.errors.technology[index]
                                              ?.desc && (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.technology[
                                                    index
                                                  ].desc
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <label
                                          htmlFor={`technology[${index}].status`}
                                          className="col-md-12 col-form-label"
                                        >
                                          Status (Active/Inactive)
                                        </label>
                                        <div className="col-md-12">
                                          <Select
                                            name={`technology[${index}].status`}
                                            placeholder="Select Status"
                                            value={fn_helper.FNFORM.getObjectValue(
                                              STATUS_OPTION,
                                              `${item.status}`
                                            )}
                                            onChange={selectedOption => {
                                              validation.setFieldValue(
                                                `technology[${index}].status`,
                                                selectedOption.id
                                              )
                                            }}
                                            onBlur={() =>
                                              validation.setFieldTouched(
                                                `technology[${index}].status`,
                                                true
                                              )
                                            }
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option =>
                                              option.name
                                            }
                                            options={STATUS_OPTION}
                                            className={`select2-selection ${
                                              validation.touched.technology &&
                                              validation.touched.technology[
                                                index
                                              ] &&
                                              validation.errors.technology &&
                                              validation.errors.technology[
                                                index
                                              ]?.status
                                                ? "input-err"
                                                : ""
                                            }`}
                                            isDisabled={pageView === "view"}
                                          />
                                          {validation.touched.technology &&
                                            validation.touched.technology[
                                              index
                                            ] &&
                                            validation.errors.technology &&
                                            validation.errors.technology[index]
                                              ?.status && (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.technology[
                                                    index
                                                  ].status
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                      <Col className="mb-3">
                                        <label
                                          className="col-md-12 col-form-label"
                                          style={{ visibility: "hidden" }}
                                        >
                                          {`_`}
                                        </label>
                                        <Button
                                          color="danger"
                                          onClick={() => console.log(index)}
                                          disabled={
                                            pageView === "view" ||
                                            validation.values.technology
                                              .length === 1
                                          }
                                        >
                                          <i className="bx bx-trash"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="card-title">
                          Feature{" "}
                          <small
                            className="text-secondary"
                            style={{ fontWeight: "lighter" }}
                          >
                            {`(Drag and drop for sorting)`}
                          </small>
                        </div>
                        <a
                          className="btn btn-success"
                          onClick={() => console.log("addGalleryImage")}
                        >
                          Add Item
                        </a>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Accordion
                          open={openFeatureAccordion}
                          toggle={toggleFeatureAccordion}
                          stayopen="true"
                          id="featureAccordion"
                          className="shadow-sm"
                        >
                          {validation.values.feature.map((item, index) => (
                            <AccordionItem key={item.id} data-id={item.id}>
                              <AccordionHeader targetId={`feature${index}`}>
                                {`${index + 1}. ${item.title}`}
                              </AccordionHeader>
                              <AccordionBody accordionId={`feature${index}`}>
                                <Row>
                                  <Col className="col-6">
                                    <Row>
                                      <Col className="col-12 mb-3">
                                        <div className="text-center">
                                          <div className="position-relative d-inline-block">
                                            <div className="position-absolute bottom-0 end-0">
                                              <Label
                                                htmlFor={`feature[${index}].image`}
                                                className="mb-0"
                                              >
                                                <div className="avatar-xs">
                                                  <div className="avatar-title bg-success border rounded-circle text-mute cursor-pointer shadow font-size-16">
                                                    <i className="bx bx-plus-medical"></i>
                                                  </div>
                                                </div>
                                              </Label>
                                              <input
                                                className="form-control d-none"
                                                name={`feature[${index}].image`}
                                                type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                // onChange={handleThumbnailChange}
                                              />
                                            </div>
                                            <div
                                              className="avatar-lg shadow-lg"
                                              style={{
                                                width: "18rem",
                                                height: "18rem",
                                              }}
                                            >
                                              <div className="avatar-title bg-white">
                                                <img
                                                  src={item.image || ""}
                                                  alt=""
                                                  className="avatar-md h-auto w-100"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {validation.touched.feature &&
                                            validation.touched.feature[
                                              index
                                            ] &&
                                            validation.errors.feature &&
                                            validation.errors.feature[index]
                                              ?.image && (
                                              <FormFeedback
                                                type="invalid"
                                                className="d-block"
                                              >
                                                {
                                                  validation.errors.feature[
                                                    index
                                                  ].image
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col className="col-6">
                                    <Row>
                                      <Col className="col-12 mb-3">
                                        <label
                                          htmlFor={`feature[${index}].title`}
                                          className="col-md-12 col-form-label"
                                        >
                                          Title
                                        </label>
                                        <div className="col-md-12">
                                          <Input
                                            type="text"
                                            name={`feature[${index}].title`}
                                            value={item.title}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            placeholder={``}
                                            invalid={
                                              !!(
                                                validation.touched.feature &&
                                                validation.touched.feature[
                                                  index
                                                ] &&
                                                validation.errors.feature &&
                                                validation.errors.feature[
                                                  index
                                                ]?.title
                                              )
                                            }
                                            disabled={pageView === "view"}
                                          />
                                          {validation.touched.feature &&
                                            validation.touched.feature[
                                              index
                                            ] &&
                                            validation.errors.feature &&
                                            validation.errors.feature[index]
                                              ?.title && (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.feature[
                                                    index
                                                  ].title
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                      <Col className="col-12 mb-3">
                                        <label
                                          htmlFor={`feature[${index}].desc`}
                                          className="col-md-12 col-form-label"
                                        >
                                          Description
                                        </label>
                                        <div className="col-md-12">
                                          <Input
                                            className="form-control"
                                            type="textarea"
                                            rows="3"
                                            placeholder=""
                                            name={`feature[${index}].desc`}
                                            value={item.desc}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                              !!(
                                                validation.touched.feature &&
                                                validation.touched.feature[
                                                  index
                                                ] &&
                                                validation.errors.feature &&
                                                validation.errors.feature[
                                                  index
                                                ]?.desc
                                              )
                                            }
                                            disabled={pageView === "view"}
                                          />
                                          {validation.touched.feature &&
                                            validation.touched.feature[
                                              index
                                            ] &&
                                            validation.errors.feature &&
                                            validation.errors.feature[index]
                                              ?.desc && (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.feature[
                                                    index
                                                  ].desc
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <label
                                          htmlFor={`feature[${index}].status`}
                                          className="col-md-12 col-form-label"
                                        >
                                          Status (Active/Inactive)
                                        </label>
                                        <div className="col-md-12">
                                          <Select
                                            name={`feature[${index}].status`}
                                            placeholder="Select Status"
                                            value={fn_helper.FNFORM.getObjectValue(
                                              STATUS_OPTION,
                                              `${item.status}`
                                            )}
                                            onChange={selectedOption => {
                                              validation.setFieldValue(
                                                `feature[${index}].status`,
                                                selectedOption.id
                                              )
                                            }}
                                            onBlur={() =>
                                              validation.setFieldTouched(
                                                `feature[${index}].status`,
                                                true
                                              )
                                            }
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option =>
                                              option.name
                                            }
                                            options={STATUS_OPTION}
                                            className={`select2-selection ${
                                              validation.touched.feature &&
                                              validation.touched.feature[
                                                index
                                              ] &&
                                              validation.errors.feature &&
                                              validation.errors.feature[
                                                index
                                              ]?.status
                                                ? "input-err"
                                                : ""
                                            }`}
                                            isDisabled={pageView === "view"}
                                          />
                                          {validation.touched.feature &&
                                            validation.touched.feature[
                                              index
                                            ] &&
                                            validation.errors.feature &&
                                            validation.errors.feature[index]
                                              ?.status && (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.feature[
                                                    index
                                                  ].status
                                                }
                                              </FormFeedback>
                                            )}
                                        </div>
                                      </Col>
                                      <Col className="mb-3">
                                        <label
                                          className="col-md-12 col-form-label"
                                          style={{ visibility: "hidden" }}
                                        >
                                          {`_`}
                                        </label>
                                        <Button
                                          color="danger"
                                          onClick={() => console.log(index)}
                                          disabled={
                                            pageView === "view" ||
                                            validation.values.feature
                                              .length === 1
                                          }
                                        >
                                          <i className="bx bx-trash"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div
                  className="mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "8px",
                  }}
                >
                  {pageView == "view" ? (
                    <>
                      <button
                        className="btn btn-primary w-md"
                        onClick={() => navigate(`/product/model`)}
                      >
                        BACK
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-primary w-md">
                        SAVE
                      </button>
                      <button
                        className="btn btn-secondary w-md"
                        onClick={() => navigate(`/product/model`)}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

FormTemplatePage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FormTemplatePage)
