import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import * as backend from "helpers/backend"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(backend.login, {
      username: user.username,
      password: user.password,
    });

    localStorage.setItem('cmsIsLogin', 'true')
    yield put(loginSuccess(response));

    window.location = '/dashboard'
  } catch (error) {
    if(typeof error.response.data !== "undefined") {
      yield put(apiError(error.response.data.message));
    }
    else {
      yield put(apiError(error.response));
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    const response = yield call(backend.logout, {});

    if (process.env.DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* socialLogin({ payload: { type, history } }) {
//   try {
//     if (process.env.DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend();
//       const response = yield call(fireBaseBackend.socialLoginUser, type);
//       if (response) {
//         history("/dashboard");
//       } else {
//         history("/login");
//       }
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     }
//     if(response)
//     history("/dashboard");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  // yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
