import { get, post, put } from "../Config"

export function SearchBannerList({ params = {} }) {
  return get({ url: `/v1/admin/bannerList`, params })
}
export function GetBannerListById({ id }) {
  return get({ url: `/v1/admin/bannerList/${id}` })
}
export function CreateBannerList({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerList`, params, data })
}
export function UpdateBannerList({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/bannerList/${id}`, params, data })
}
export function DeleteBannerList({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerList/mutiDelete`, params, data })
}
