import React, { useState } from "react"
import PropTypes from 'prop-types'
import { generatePath, Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const { title, breadcrumbItems } = props
  const itemLength = breadcrumbItems.length

  // const [breadcrumbPath, setBreadcrumbPath] = useState('');

  const callBreadcrumbPath = () => {
    let _bc = [];
    let _newBC = '';
    breadcrumbItems.map((item, key) => {
      _newBC = _newBC.concat(`${item.link}/`)
      _bc.push(`/${_newBC.substring(0, _newBC.length - 1)}`)
    })
    
    return _bc
  }

  const breadcrumLink = callBreadcrumbPath();

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{title}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbItems.map((item, key) => (
                <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                  { (key + 1 === itemLength) ? item.title : <Link to={`${breadcrumLink[key]}`}>{item.title}</Link> }
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string
}

export default Breadcrumb
