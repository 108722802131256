import React, { useEffect, useState, useContext } from "react";
import { Card, Form, CardBody, CardSubtitle, Container, Spinner } from "reactstrap"
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import helper from "helpers/fn_helper";
import { UploadFile } from "services/api/module/Upload";
import axios from "axios";
import Spinners from "./Common/Spinner";
import fn_helper from "helpers/fn_helper";

const HOST_FILE = "https://g99-static.sgp1.cdn.digitaloceanspaces.com"

const UploadFileAPI = ({
  label = "Dropzone",
  description = "description",
  onChange = () => console.log('onChange!'),
  prefixName = "broadcast",
  value = null,
  upload = "Image",
  typeUpload = "",
  widthSize = 0,
  heightSize = 0,
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  const [firstLoad, setFirstLoad] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [imageSize, setImageSize] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);

  async function handleAcceptedFiles(files) {
    console.log("🚀 ~ handleAcceptedFiles ~ files:", files)
    const _fileData = files[0]
    const tmpfilename = _fileData?.name ?? '.png'
    const typeSplit = `${tmpfilename}`.split('.')
    const typeFile = typeSplit[typeSplit.length - 1]

    const fileSize = _fileData.size
    const sizeInMB = fileSize / 1024 / 1024; // Convert bytes to MB

    let updateImageSize = '0x0'

    let CONF_FILE_TYPE = ['png','jpg','jpeg']
    let CONF_FILE_SIZE = 10
    let JOIN = ', '
    // upload = "Image"
    if (upload == "Video") {
      CONF_FILE_TYPE = ['mp4']
      CONF_FILE_SIZE = 50
      JOIN = ''

      // setFileURL(URL.createObjectURL(files[0]));
      setFileData(files[0]);
    }

    if (CONF_FILE_TYPE.includes(typeFile)) {
      const { width, height } = await helper.FNFILE.getImageDimensions(_fileData)
      updateImageSize = `${width}x${height}`

      if (typeUpload == "ImageMap") {
        let minSize = 1024;
        console.log("🚀 ~ handleAcceptedFiles ~ width, height:", width, height)
        console.log("🚀 ~ handleAcceptedFiles ~ 1", width >= minSize)
        console.log("🚀 ~ handleAcceptedFiles ~ 2", height >= minSize)
        console.log("🚀 ~ handleAcceptedFiles ~ 3", width == height)
        if (!(width == widthSize && height == heightSize)) {
          mAlert.info({
            title: "Alert",
            subTitle: `File resolution ${widthSize} x ${heightSize}`,
            content: "",
            onClose: () => {
            },
          })
          return
        }
      }

      if (sizeInMB <= CONF_FILE_SIZE) {
        // setFileURL(URL.createObjectURL(files[0]))
        setFileData(files[0])
        setImageSize(updateImageSize)
      } else {
        mAlert.info({
          title: "Alert",
          subTitle: `File size max ${CONF_FILE_SIZE} MB`,
          content: "",
          onClose: () => {
          },
        })
      }
    } else {
      mAlert.info({
        title: "Alert",
        subTitle: `File type ${CONF_FILE_TYPE.join(JOIN)} only`,
        content: "",
        onClose: () => {
        },
      })
    }
    

    // formatBytes(file.size)
    // เซ็ตไว้ดูก่อน

    // files.map(file =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: formatBytes(file.size),
    //   })
    // )
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const uploadFile = async (_fileData = {}) => {
    const tmpfilename = _fileData?.name ?? '.png'
    const tmpTypeFile = `${tmpfilename}`.split('.')
    const typeFile = tmpTypeFile[tmpTypeFile.length - 1]

    const timestamp = moment().unix();
    const fileName = `${prefixName}-${timestamp}.${typeFile}`

  
    // const FILE_LINK = HOST_FILE + `/` + fileName
    setLoadingImage(true)
    const FILE_LINK = await fetchUploadFile(_fileData, fileName)
    setTimeout(() => setLoadingImage(false), 100)

    console.log("🚀 ~ uploadFile ~ FILE_LINK:", FILE_LINK)
    if (FILE_LINK) {
      onChange([FILE_LINK, imageSize])
    }
  };

  const fetchUploadFile = async (selectedFile, fileName = '') => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    return new Promise(async (resolve, reject) => {
      try {
        const response = await UploadFile({ formData: formData, bucketName: 'banner', fileName: fileName })
        const resData = response.data
        if (resData.status === 201) {
          console.log('resData >>', resData)
          let fetching = true
          while (fetching) {
            try {
              const resImg = await fetch(resData?.data?.url) // Fetch Data Free
              console.log("🚀 ~ returnnewPromise ~ resImg:", resImg)
              fetching = false
              break;
            } catch(e) {
              console.log('e fetch img', e)
            }
            await fn_helper.FN.delayTime(500)
          }
          resolve(resData?.data?.url ?? null)
        }
      } catch (e) {
          console.log(e)
          resolve(null)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    })
    
  }

  const onClearState = () => {
    setFileData(null)
    setFileURL(null)
    setFirstLoad(false)
    onChange("")
  }

  useEffect(() => {
    if (fileData) {
      uploadFile(fileData)
    }
  }, [fileData])

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ value:", value)
    if (value) {
      setFileURL(value)
      setFirstLoad(true)
    } else {
      onClearState()
    }
  }, [value])


  let acceptFile = { 'image/*': ['.png','jpg','.jpeg'] }
  description = 'File type png, jpg, jpeg only, Max filesize 10 MB '
  if (upload == "Video") {
    acceptFile = { 'video/*': ['.mp4'] }
    description = 'File type mp4 only, Max file size 50 MB'
  }
  if (typeUpload == "ImageMap") {  
    description = `File type png, jpg, jpeg only, Max filesize 10 MB`
    if (widthSize) {
      description += `, File resolution `
      description += `${widthSize} x `
    }
    if (heightSize) {
      description += `${heightSize}`
    }
  }

  return (
    <React.Fragment>
      <Card>
        <div>
          <h6 className="card-title">{label}</h6>
          <CardSubtitle className="mb-3">
            {description}
          </CardSubtitle>
          <Form>
              {
                fileData || firstLoad ? (
                  <>
                      {
                        upload === "Image" ? (
                          <div className="dropzone bImageBox">
                            {
                              loadingImage
                              ? <Spinner color="primary" className='position-absolute top-50 start-50' />
                              : <img data-dz-thumbnail="" src={fileURL}/>
                            }
                            {/* {fileURL} <br/> */}
                            {/* {JSON.stringify(fileData)} */}
                          </div>
                        )
                        : 
                        upload === "Video" ? (
                          <div className="dropzone bImageBox">
                            <div className="bVideoUpload">
                              <div className="bx bx-video-recording bx-lg"></div>
                              <h4>Video File</h4>
                            </div>
                          </div>
                        )
                        : <>NULL</>

                      }
                    <div className="mt-4">
                        <button
                          type="button"
                          className="btn btn-danger "
                          onClick={onClearState}
                        >
                          Delete
                        </button>
                      </div>
                  </>
                ) :
                <>
                  <Dropzone
                    onDrop={acceptedFiles => {

                      handleAcceptedFiles(acceptedFiles)
                    }}
                    // accept= {{
                    //   'image/*': ['.png','jpg','.jpeg']
                    // }}
                    accept= {acceptFile}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop {upload} file here or click to upload.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </>
              }
          </Form>

          {/* <div className="mt-4">
            <button
              type="button"
              className="btn btn-primary "
              onClick={() => uploadFile(fileData)}
            >
              Upload
            </button>
          </div> */}
          
         

        </div>
      </Card>
    </React.Fragment>
  )
}

export default UploadFileAPI
