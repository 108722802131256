import { get, post, put } from "../Config"

export function getPointType({ params = {} }) {
  return get({ url: `/v1/admin/point/type/`, params })
}
export function createPointType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/type/create`, params, data })
}
export function updatePointType({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/point/type/update/${id}`, params, data })
}
export function deletePointType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/type/delete`, params, data })
}

export function getPointConfig({ params = {} }) {
  return get({ url: `/v1/admin/point/config/`, params })
}
export function createPointConfig({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/config/create`, params, data })
}
export function updatePointConfig({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/point/config/update/${id}`, params, data })
}
export function deletePointConfig({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/config/delete`, params, data })
}

export function getPointSpecialEventConfig({ params = {} }) {
  return get({ url: `/v1/admin/point/special-event-config/`, params })
}
export function createPointSpecialEventConfig({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/special-event-config/create`, params, data })
}
export function updatePointSpecialEventConfig({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/point/special-event-config/update/${id}`, params, data })
}
export function deletePointSpecialEventConfig({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/special-event-config/delete`, params, data })
}