import { get, post, put } from "../Config"

export function SearchBannerPage({ params = {} }) {
  return get({ url: `/v1/admin/bannerPage`, params })
}
export function GetBannerPageById({ id }) {
  return get({ url: `/v1/admin/bannerPage/${id}` })
}
export function CheckBannerPage({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerPage/checkField`, params, data })
}
export function CreateBannerPage({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerPage`, params, data })
}
export function UpdateBannerPage({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/bannerPage/${id}`, params, data })
}
export function DeleteBannerPage({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerPage/mutiDelete`, params, data })
}
